import "./styles.css";

import cta from "../../assets/call_fone.png";

const ctaTel = () => {
  return (
    <figure id="ctaTel">
      <a href="tel:26361677">
        <img src={cta} alt="Chamada de ação para Telefone" />
      </a>
    </figure>
  );
};

export default ctaTel;
