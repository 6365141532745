import React from "react";
import "./styles.css";

import cta from "../../assets/call_catalogo.png";

const ctaCatalogo = (props) => {
  const { pdf } = props;

  return (
    <figure id="ctaCatalogo">
      <a href={pdf} target='_blank' rel="noreferrer">
        <img src={cta} alt="Chamada de ação para baixar Catalogo" />
      </a>
    </figure>
  );
};

export default ctaCatalogo;
