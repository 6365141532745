import React from "react";
import "./styles.css";

import CTAForm from "../../components/CTAForm";
import CTAWpp from "../../components/CTAWpp";
import CTATel from "../../components/CTATel";

import agilidade from "../../assets/agilidade.png";
import compromisso from "../../assets/compromisso.png";
import qualidade from "../../assets/qualidade.png";
import entrega from "../../assets/entrega.png";

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <h2 className="title">Nosso DIFERENCIAL</h2>

          <div className="gallery">
            <figure>
              <img src={agilidade} alt="agilidade" />
            </figure>

            <figure>
              <img src={compromisso} alt="compromisso" />
            </figure>

            <figure>
              <img src={qualidade} alt="qualidade" />
            </figure>

            <figure>
              <img src={entrega} alt="entrega rápida" />
            </figure>
          </div>

          <CTAForm />
        </div>
      </article>

      <article className="content2">
        <div className="container">
          <h2 className="title">
            Você, do ramo alimentício, pode contar conosco para fazer seu
            comércio crescer e expandir.
          </h2>

          <p className="subtitle">
            Entre em contato agora mesmo com nossa equipe comercial através do
            WhatsApp ou telefone:
          </p>

          <div className="bottom">
            <CTAWpp />
            <CTATel />
          </div>
        </div>
      </article>
    </section>
  );
};

export default Content;
