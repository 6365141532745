import "./styles.css";

import CTAForm from "../../components/CTAForm";
import CTACatalogo from "../../components/CTACatalogo";

import catalogo1 from "../../assets/catalogo1.pdf";
import catalogo2 from "../../assets/catalogo2.pdf";

import card1 from "../../assets/card1.png";
import card2 from "../../assets/card2.png";
import card3 from "../../assets/card3.png";

import about5 from "../../assets/about5.png";
import about5Mobile from "../../assets/mobile/about5.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">Nossos produtos</h2>
          <h3 className="subtitle">
            Trabalhamos com os melhores fornecedores de alimentos!
          </h3>
        </div>

        <div className="about-bg">
          <div className="container">
            <h2 className="title">Sinônimo de qualidade desde 1969!</h2>
            <p className="text">
              Especialista em Carne Seca e Pertences de Feijoada há mais de 50
              anos no mercado. São produtos com origem rigorosamente
              selecionados, garantindo o alto compromisso com a qualidade.
            </p>
            <p className="text2">
              Por isso nossa maior satisfação é oferecer um sabor inconfundível
              e uma experiência inesquecível.
            </p>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">
            Nossa carne seca é o produto que falta no seu estabelecimento.
          </h2>
          <p className="subtitle">
            Feito com cortes dianteiros, traseiros ou da ponta de agulha, o
            jerked beef é uma carne bovina que, para ser produzida, passa por um
            cuidadoso processo de adição de sais de cura, o que permite um tempo
            menor de exposição ao sol. É reconhecido por sua alta umidade, sabor
            inconfundível, cor bem avermelhada e textura muito macia. Antes de
            ser preparado, deve ser dessalgado.
          </p>
          <p className="text">Pensou em Feijoada, pensou Coringa.</p>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <div className="gallery">
            <figure>
              <img src={card1} alt="Carne seca | Coringa" />
            </figure>

            <figure>
              <img src={card2} alt="Carne seca | Coringa" />
            </figure>

            <figure>
              <img src={card3} alt="Feijoada" />
            </figure>
          </div>

          <div className="bottom">
            <CTAForm />
            <CTACatalogo pdf={catalogo1} />
          </div>
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <h2 className="title">Quem prova nunca esquece!</h2>
          <p className="text">
            Siberiann é especializada em massas e congelados, como: Pão de
            queijo, pastel, mini churros, dadinho de tapioca, lasanha entre
            outros! Por isso, o foco é oferecer o melhor em alimentos para você
            e sua família.
          </p>
          <p className="text2">
            Tem como missão garantir que a sua experiência seja perfeita. Para
            isso, vivem em uma busca constante por qualidade e excelência no
            atendimento!
          </p>
        </div>
      </article>

      <article className="about5">
        <div className="left">
          <h2 className="title">
            Casquinha crocante e muito recheio, nos sabores doce de leite ou
            chocolate, os mini churros fazem sucesso.
          </h2>
          <p className="text">
            Um produto congelado com preparo rápido, uma sobremesa que está
            ganhando espaço e vai conquistar você!
          </p>

          <div className="bottom">
            <CTAForm />
            <CTACatalogo pdf={catalogo2} />
          </div>
        </div>

        <figure className="right">
          <img
            src={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? about5Mobile
                : about5
            }
            alt="Pacotes de Mini Churros"
          />
        </figure>
      </article>
    </section>
  );
};

export default About;
