import React from "react";
import "./styles.css";

import Form from "../../components/Form";
import CTAForm from "../../components/CTAForm";

import header2 from "../../assets/header2.png";
import header2Mobile from "../../assets/mobile/header2.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <div className="left">
            <h1 className="title">
              Qualidade em alimentos para o seu negócio, é com a Souza Lima!
            </h1>

            <p className="text">
              Trabalhamos com os melhores fornecedores e com produtos de
              excelente qualidade. Estamos nos melhores bares, restaurantes,
              lanchonetes e mercados de São Paulo.
            </p>

            <CTAForm />
          </div>

          <Form />
        </article>
      </section>

      <section className="mobile" id="formMobile">
        <article className="container">
          <Form />
        </article>
      </section>

      <section className="header2">
        <div className="left">
          <h2 className="title">Quem somos?</h2>
          <p className="text">
            Souza Lima é uma empresa de representação comercial que atua com
            produtos do ramo alimentício.
          </p>
          <CTAForm />
        </div>

        <figure className="right">
          <img
            src={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? header2Mobile
                : header2
            }
            alt="Cozinha"
          />
        </figure>
      </section>
    </header>
  );
};

export default Header;
