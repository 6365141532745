import React, { useRef } from "react";
import "./styles.css";

import { useNavigate } from "react-router-dom";

import Enviar from "../../assets/enviar.png";
import EnviarMobile from "../../assets/mobile/enviar.png";

const Form = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();

  const scriptURL =
    "https://script.google.com/macros/s/AKfycbxp3Mz2Zcd6INCmEIJmkbsQ9DMT7dy4Yvw_KSVbXYknBMaVdnJqCPrEs-MF6lC6HMWD/exec";

  const phoneMask = (e) => {
    if (e.target.value.length < 10) {
      return alert("Número inválido");
    } else if (e.target.value.length === 10) {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{4})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    } else {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{5})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    document.getElementById("submit").disabled = true;
    document.getElementById("submit").style.cursor = "default";
    document.getElementById("submit-image").style.opacity = 0.5;

    fetch(scriptURL, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then(() => {
        document.getElementById("form").reset();
        navigate("/sucesso");
      })
      .catch((err) => console.log(err));
  };

  return (
    <form ref={formRef} name="google-sheet" onSubmit={handleSubmit} id="form">
      <h2 className="form-title">Solicite uma cotação</h2>
      <h3 className="form-subtitle">
        Preencha o formulário, entraremos em contato.
      </h3>
      <div className="form-div1">
        <label htmlFor="name">
          Nome: <span>*</span>
        </label>
        <input type="text" id="name" name="nome" required />

        <label htmlFor="tel">
          Telefone: <span>*</span>
        </label>
        <input
          type="tel"
          id="tel"
          name="telefone"
          onBlur={phoneMask}
          minLength="11"
          maxLength="12"
          required
        />

        <label htmlFor="email">
          E-mail: <span>*</span>
        </label>

        <input type="email" id="email" name="email" required />
        <label htmlFor="cnpj">
          CNPJ: <span>*</span>
        </label>
        <input type="text" id="cnpj" name="cnpj" required />
      </div>

      <div className="form-div2">
        <div className="checkbox">
          <input
            type="checkbox"
            id="check"
            name="contato comercial"
            value="Quero"
            defaultChecked
          />
          <label htmlFor="check">Aceito receber contato comercial</label> <br />
          <button type="submit" id="submit">
            <figure>
              <img
                src={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? EnviarMobile
                    : Enviar
                }
                alt="Enviar"
                id="submit-image"
                height={50}
              />
            </figure>
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
